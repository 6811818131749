import { getAllActivities } from "@/services/ActivityService";

export const activities = {
  namespaced: true,
  state: { activities: [] },
  getters: {
    getActivities(state) {
      return state.activities;
    },
  },
  actions: {
    async fetchActivities({ commit }) {
      const response = await getAllActivities().then((resp) => resp.results);
      commit("SET_ACTIVITIES", response);
      return response;
    },
  },
  mutations: {
    SET_ACTIVITIES(state, data) {
      state.activities = data;
    },
  },
};
