<template>
  <v-card outlined class="mb-4" md="12" elevation="2">
    <v-card-title>Join E.S.A.V. De Beunbazen</v-card-title>
    <v-divider />
    <v-card-text>
      Nice, you are interested to join us! To join our association you can fill
      in this form. We try to mail you back within 5 working days with further
      information on how to become a member. If you have any questions, feel
      free to ask it on the contact page or ask one of our members if you know
      one.
      <br />
      It is not mandatory to own a car<br />
      <v-divider />
      <JoinUsForm class="mt-4" />
    </v-card-text>
  </v-card>
</template>
<script>
import JoinUsForm from "@/components/JoinUsForm.vue";

export default {
  name: "JoinUsPage",
  components: { JoinUsForm },
};
</script>

<style scoped></style>
