<template>
  <v-card>
    <v-card-title>Downloads</v-card-title>
    <v-card-text class="mt-4">
      <strong>Downloads</strong><br />
      <v-simple-table>
        <template>
          <thead>
            <tr>
              <th class="text-left">Title</th>
              <th class="text-left">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="file in files" v-bind:key="file.id">
              <td>
                <a @click="downloadFile(file.id, file.file)">{{
                  file.title
                }}</a>
              </td>
              <td>
                {{ file.description }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <div class="text-center" v-if="!is_loading">
      <v-container>
        <v-row justify="center">
          <v-col cols="6">
            <v-container class="max-width">
              <v-pagination
                v-model="page_nr"
                class="my-4"
                :length="total_pages"
                @input="getPage"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import { getFilesList } from "@/services/FileService";
import Vue from "vue";

export default {
  name: "FilesPage",
  data: () => ({
    is_loading: false,
    files: [],
    total_files: 0,
    page_nr: 0,
  }),
  computed: {
    total_pages() {
      if (this.total_files > 0) {
        return Math.ceil(this.total_files / 10);
      }
      return 1;
    },
  },
  methods: {
    downloadFile: function (id, filename) {
      Vue.prototype
        .$http({
          url: "file/" + id + "/",
          method: "GET",
          responseType: "blob",
        })
        .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", filename.split("/").pop()); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    async getPage(page_id) {
      this.is_loading = true;
      await getFilesList(page_id)
        .then((response) => {
          this.total_files = response.count;

          this.files = response.results;
          this.page_nr = page_id;
          this.is_loading = false;
        })
        .catch(() => (this.is_loading = false));
    },
  },
  async mounted() {
    this.is_loading = true;
    await getFilesList()
      .then((resp) => {
        this.is_loading = false;
        this.files = resp.results;
        this.total_files = resp.count;
      })
      .catch(() => (this.is_loading = false));
  },
};
</script>

<style scoped></style>
