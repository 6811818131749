import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    defaultTheme: 'lustrumTheme',
    theme: {
        themes: {
            light: {
                primary: '#212a2f',
                'main-color': '#212a2f',
                'lustrumgold': '#caaa39',

            },
            dark: {
                primary: '#212a2f',
                'main-color': '#212a2f',
                'lustrumgold': '#caaa39',
            }
        },
    },
})
