<template>
  <v-card outlined class="mb-4" md="12" elevation="2" :loading="is_loading">
    <v-card-title>Members List</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      This information may not be shared and is only used for contacting fellow
      members.
      <v-simple-table>
        <template>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Phone</th>
              <th class="text-left">E-mail</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in this.members" :key="item.pk">
              <td>
                <router-link router text :to="'/members/' + item.pk">{{
                  item.name
                }}</router-link>
              </td>
              <td>
                {{ item.phone }}
              </td>
              <td>
                {{ item.email }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <div class="text-center" v-if="!is_loading">
      <v-container>
        <v-row justify="center">
          <v-col cols="6">
            <v-container class="max-width">
              <v-pagination
                v-model="page_nr"
                class="my-4"
                :length="total_pages"
                @input="getPage"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import { getMembersList } from "@/services/MemberService";

export default {
  name: "MembersListComponent",
  data() {
    return {
      is_loading: false,
      page_nr: 1,
      members: [],
      total_members: 0,
    };
  },
  computed: {
    total_pages() {
      if (this.total_members > 0) {
        return Math.ceil(this.total_members / 10);
      }
      return 1;
    },
  },
  methods: {
    async getPage(page_id) {
      this.is_loading = true;
      await getMembersList(page_id)
        .then((response) => {
          this.total_members = response.count;

          this.members = response.results;
          this.page_nr = page_id;
          this.is_loading = false;
        })
        .catch(() => (this.is_loading = false));
    },
  },

  async mounted() {
    this.is_loading = true;
    await getMembersList()
      .then((response) => {
        this.total_members = response.count;
        this.members = response.results;
        this.is_loading = false;
      })
      .catch(() => (this.is_loading = false));
  },
};
</script>

<style scoped></style>
