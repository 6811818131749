<template functional>
    <v-card outlined class="mb-4" md="12" elevation="2">
        <v-card-title>Lustrum activitees</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            Academic year 2023 - 2024 E.S.A.V. celebrates their fifth anniversary, their first Lustrum. Beneath you can find
            an overview of all special Lustrum activitees.
            <v-timeline dense clipped class="mt-4">
                <v-timeline-item hide-dot class="mb-4">
                    <span class="lustrumgold--text" style="font-weight: bold;">First Quartile</span>
                </v-timeline-item>
                <v-timeline-item class="mb-4"><span class="lustrumgold--text">25-09-2023: </span>Lustrum
                    Opening</v-timeline-item>
                <v-timeline-item class="mb-4">
                    <span class="lustrumgold--text">25-09-2023:</span> Lustrum Outdoor Meet
                </v-timeline-item>
                <v-timeline-item><span class="lustrumgold--text">05-10-2023:</span> Lustrum Stunt</v-timeline-item>
                <v-timeline-item><span class="lustrumgold--text">12-10-2023:</span> Lustrum Safari</v-timeline-item>
                <v-timeline-item hide-dot class="mb-4">
                    <span class="lustrumgold--text" style="font-weight: bold;">Second Quartile</span>
                </v-timeline-item>
                <v-timeline-item><span class="lustrumgold--text">25-11-2023:</span> Lustrum Dealer
                    Shopping</v-timeline-item>
                <v-timeline-item><span class="lustrumgold--text">09-12-2023:</span> Lustrum Eindhoven Meet +
                    Tour</v-timeline-item>
                <v-timeline-item hide-dot class="mb-4">
                    <span class="lustrumgold--text" style="font-weight: bold;">Third Quartile</span>
                </v-timeline-item>
                <v-timeline-item><span class="lustrumgold--text">10-02-2024 - 18-02-2024:</span> Lustrum Trip; Skandinavia
                    Vinterekspedision</v-timeline-item>
                <v-timeline-item><span class="lustrumgold--text">29-02-2024:</span> Lustrum Party</v-timeline-item>
                <v-timeline-item><span class="lustrumgold--text">03-03-2024:</span> Lustrum Military Meet</v-timeline-item>
                <v-timeline-item><span class="lustrumgold--text">16-03-2024:</span> Lustrum Militart
                    Vehicles</v-timeline-item>
                <v-timeline-item hide-dot class="mb-4">
                    <span class="lustrumgold--text" style="font-weight: bold;">Fourth Quartile</span>
                </v-timeline-item>
                <v-timeline-item><span class="lustrumgold--text">28-04-2024:</span> Lustrum Castle
                    Concours</v-timeline-item>
                <v-timeline-item><span class="lustrumgold--text">25-05-2024 - 26-05-2024:</span> Lustrum Nürburgring
                    Weekend</v-timeline-item>
                <v-timeline-item><span class="lustrumgold--text">01-06-2024:</span> Lustrum Beach Day</v-timeline-item>
                <v-timeline-item hide-dot class="mb-4">
                    <span class="lustrumgold--text" style="font-weight: bold;">First Quartile 2024</span>
                </v-timeline-item>
                <v-timeline-item><span class="lustrumgold--text">25-09-2024:</span> Lustrum Gala</v-timeline-item>
            </v-timeline>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "LustrumPage",
};
</script>

<style scoped></style>
