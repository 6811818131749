<template>
  <v-card class="mx-auto" tile elevation="2" :loading="is_loading">
    <v-card-title> Activity Calendar </v-card-title>
    <v-divider></v-divider>
    <v-alert
      v-if="this.getCalendar.length === 0 && !is_loading"
      color="main-color"
      dark
      class="ma-4"
    >
      There are no upcoming activities
    </v-alert>
    <v-hover
      v-for="(item, index) in this.getCalendar"
      v-bind:key="index"
      v-slot="{ hover }"
      enabled
    >
      <v-list-item
        three-line
        :elevation="hover ? 12 : 2"
        :class="hover ? 'blue-grey lighten-5' : ''"
      >
        <v-list-item-content>
          <!--        <div class="text-overline mb-4">-->
          <!--          -->
          <!--        </div>-->
          <v-list-item-title class="text-h5 mb-1">
            {{ item.summary }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ item.startutc }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar tile size="80" color="grey">
          <v-img :src="item.location"></v-img>
        </v-list-item-avatar>
      </v-list-item>
    </v-hover>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Calendar",

  data: () => ({
    calendar: [],
    is_loading: false,
  }),
  computed: {
    ...mapGetters("calendar", ["getCalendar"]),
  },
  methods: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    ...mapActions("calendar", ["fetchCalendar"]),
  },
  async mounted() {
    this.is_loading = true;
    await this.fetchCalendar()
      .then(() => (this.is_loading = false))
      .catch(() => (this.is_loading = false));
    this.is_loading = false;
  },
};
</script>

<style scoped></style>
