<template>
  <div>
    <v-alert v-if="error" dense type="error">
      Could not load news articles. Try refreshing the page.
    </v-alert>

    <v-skeleton-loader
      class="mx-auto"
      type="card"
      v-if="is_loading_news"
    ></v-skeleton-loader>
    <div v-if="!is_loading_news">
      <NewsItem
        v-for="n in news"
        v-bind:key="n.id"
        v-bind:title="n.title"
        v-bind:content="n.content"
        v-bind:photo="n.photo"
        v-bind:date="n.pub_date"
        v-bind:author="n.author"
      />
    </div>
  </div>
</template>

<script>
import NewsItem from "../components/NewsItem";
import { getAllNews } from "@/services/NewsService";

export default {
  name: "HomePage",
  components: {
    NewsItem,
  },
  data() {
    return {
      is_loading_news: false,
      news: [],
      error: false,
    };
  },
  computed: {
    // if user is already logged in
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  async mounted() {
    this.is_loading_news = true;
    getAllNews()
      .then((response) => {
        this.is_loading_news = false;
        this.news = response;
      })
      .catch(() => {
        this.is_loading_news = false;
        this.error = true;
      });
  },
};
</script>

<style scoped></style>
