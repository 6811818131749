import Vue from "vue";

const getAllActivities = () =>
  Vue.prototype.$http.get("activities/").then((response) => {
    return response.data;
  });

// you can pass arguments to use as request parameters/data
const getActivity = (id) =>
  Vue.prototype.$http.get("activities/" + id).then((response) => {
    return response.data;
  });

const createActivityEntry = (id) =>
  Vue.prototype.$http
    .post("activities/" + id + "/set_participant/")
    .then((response) => {
      return response.data;
    });
const deleteActivityEntry = (id) =>
  Vue.prototype.$http
    .delete("activities/" + id + "/set_participant/")
    .then((response) => {
      return response.data;
    });

const getAllParticipants = (id) =>
  Vue.prototype.$http
    .get("activities/" + id + "/all_participants/")
    .then((response) => {
      return response.data;
    });

const getPaymentLink = (id) =>
  Vue.prototype.$http
    .get("activities/" + id + "/get_payment_link/")
    .then((response) => {
      return response.data;
    });

export {
  getAllActivities,
  getActivity,
  createActivityEntry,
  deleteActivityEntry,
  getAllParticipants,
  getPaymentLink,
};
