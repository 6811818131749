import axios from "axios";
import store from "../store";
import Vue from "vue";
import { router } from "@/router";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});
httpClient.interceptors.request.use(async (config) => {
  const accessToken = localStorage.getItem("accessToken");

  //checking if accessToken exists
  if (accessToken) {
    config.headers["Authorization"] = "Bearer " + accessToken;
  }
  return config;
});

httpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, config } = error;
    if (response.status === 401) {
      if (!config.headers["Authorization"]) {
        // user accessed something they weren't allowed
        Vue.notify({
          type: "error",
          title: "Permission Denied",
          text: "You are not allowed to view this page",
        });
        return;
      }
      localStorage.removeItem("accessToken");
      let refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        return store
          .dispatch("auth/refreshToken", refreshToken)
          .then((token) => {
            config.headers["Authorization"] = "Bearer " + token;
            return httpClient(config);
          })
          .catch((e) => {
            console.log(e);
            let routerPushContext;
            console.log(router.currentRoute.path);
            if (router.currentRoute.path !== "/") {
              routerPushContext = {
                name: "login",
                query: { redirect: router.currentRoute.fullPath },
              };
            }
            store.dispatch("auth/logOut", {
              routerPush: routerPushContext,
            });
            delete config.headers["Authorization"];
            return httpClient(config);
          });
      }
    } else {
      console.error(error.response.status, error.message);
      Vue.notify({
        type: "error",
        title: "Server Error",
        text: "An error has occurred!",
      });
    }
  }
);

export default httpClient;
