<template>
  <v-card
    elevation="2"
    class="ma-4 d-flex flex-column"
    min-height="calc(100% - 12px)"
    v-if="show"
  >
    <v-img
      class="white--text align-end"
      height="290px"
      max-height="290px"
      :src="'https://api.esav.nl' + car.photo"
    >
      <v-card-title style="background-color: rgba(0, 0, 0, 0.25)"
        >{{ car.nickname }}
      </v-card-title>
    </v-img>

    <v-card-subtitle class="pb-0">
      Owned by:
      <router-link link :to="'/members/' + car.owner.id"
        >{{ car.owner.name }}
      </router-link>
    </v-card-subtitle>

    <v-card-text class="text--primary">
      <span v-html="car.specs"></span>
    </v-card-text>
    <v-spacer />
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-chip-group
        active-class="deep-purple accent-4 white--text"
        disabled-class="deep-purple accent-4 white--text"
        column
      >
        <v-chip label>{{ car.make }}</v-chip>
        <v-chip label>{{ car.carmodel }}</v-chip>

        <v-chip v-if="car.chasis_type" label>{{ car.chasis_type }} </v-chip>

        <v-chip v-if="car.current_state" label>{{ car.current_state }} </v-chip>

        <v-chip v-if="car.currently_owns" label>In garage</v-chip>
        <v-chip v-if="car.currently_owns === false" label>Legacy</v-chip>
      </v-chip-group>
      <v-row class="mt-2" v-if="isOwner">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ma-2" secondary outlined v-bind="attrs" v-on="on">
              <v-icon dark> mdi-wrench </v-icon>
              Edit car
            </v-btn>
          </template>

          <span>Available soon</span>
        </v-tooltip>
        <v-btn class="ma-2" color="red" dark @click="deleteCar">
          <v-icon> mdi-cancel </v-icon>
          Delete
        </v-btn>
      </v-row>
    </v-card-text>
    <ConfirmDialog ref="confirm" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { deleteCar } from "@/services/GarageService";
import Vue from "vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default {
  name: "CarCard",
  components: { ConfirmDialog },
  props: ["car"],
  data() {
    return {
      show: true,
    };
  },
  computed: {
    ...mapGetters({ current_user_id: "auth/getCurrentUserId" }),
    isOwner() {
      return parseInt(this.current_user_id) === this.car.owner.id;
    },
  },
  methods: {
    async deleteCar() {
      if (
        await this.$refs.confirm.open(
          "Are you sure?",
          "Are you sure you want to delete this car?"
        )
      ) {
        await deleteCar(this.car.id).then(() => {
          this.show = false;
          Vue.notify({
            type: "success",
            title: "Car deleted",
            message: "Car has been deleted",
          });
        }).catch(() => {
          Vue.notify({
            type: "error",
            title: "ERROR",
            message: "Could not delete car",
          })
        });
      }
    },
  },
};
</script>

<style scoped></style>
