<template>
  <v-form :loading="loading" ref="form" v-model="valid" lazy-validation>
    <v-alert v-if="error" type="error" dense>
      {{ this.error }}
    </v-alert>
    <v-alert dense v-if="success" type="success">
      The form has been sent. We will get back to you as soon as possible.
    </v-alert>
    <v-text-field
      v-model="name"
      :rules="nameRules"
      label="Name"
      required
      v-if="!success"
    ></v-text-field>

    <v-text-field
      v-model="email"
      :rules="emailRules"
      label="E-mail"
      required
      v-if="!success"
    ></v-text-field>

    <v-text-field
      v-model="phone"
      :rules="phoneRules"
      label="Phone number"
      required
      v-if="!success"
    ></v-text-field>

    <v-select
      v-model="select"
      :items="items"
      :rules="[(v) => !!v || 'Institute is required']"
      label="Educational Institute"
      required
      v-if="!success"
    ></v-select>

    <v-checkbox
      v-model="checkbox"
      :rules="[(v) => !!v || 'You must agree to continue!']"
      label="I consent to my data being used to be stored for a maximum time of 1 month and may be used once to contact me for further information about ESAV."
      required
      v-if="!success"
    ></v-checkbox>
    <v-btn
      color="primary"
      class="mr-4 lustrumgold--text"
      :disabled="loading"
      v-if="!success"
      @click="validate"
    >
      Send
    </v-btn>
  </v-form>
</template>

<script>
import Vue from "vue";

export default {
  name: "JoinUsForm",
  data: () => ({
    valid: true,
    name: "",
    nameRules: [(v) => !!v || "Name is required"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required so we can get back to you.",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    phone: "",
    phoneRules: [
      (v) => !!v || "Phone number is required so we can get back to you.",
    ],
    select: null,
    items: ["Fontys Eindhoven", "TU/e", "Other"],
    checkbox: false,
    success: false,
    error: null,
    loading: false,
  }),

  methods: {
    async validate() {
      this.loading = true;
      const is_valid = this.$refs.form.validate();
      if (is_valid === true) {
        //send it
        await Vue.prototype.$http
          .post("/send-form/", {
            from: this.name,
            subject: "Join us Form from " + this.name,
            content:
              "contact details: \nemail: " +
              this.email +
              "\nschool: " +
              this.select +
              "\nphone: " +
              this.phone,
          })
          .then(() => {
            this.error = null;
            this.success = true;
          })
          .catch(() => {
            this.error =
              "Form was not sent, something went wrong. Try again or mail to bestuur@esav.nl";
          });
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
