<template functional>
  <v-card>
    <v-card-title>E.S.A.V. Committees</v-card-title>
    <v-divider />
    <v-expansion-panels class="mb-6" v-model="panel" multiple>
      <v-expansion-panel v-model="panel">
        <v-expansion-panel-header>
          <h3>Airco</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          The commission which makes all the amazing activities possible! Planning nice meets and other events, making
          sure there is enough coffee & snacks. In addition also making great trips possible in Eindhoven and the rest of
          Europe. Not to forget, organizing the borrels & BBQs! Thus getting all members together in various ways.<br><br>
          So if you would like to help creating these awesome activities sign up now and we'll make it happen!
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-model="panel">
        <v-expansion-panel-header>
          <h3>HuisCie</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          E.S.A.V. HQ committee
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-model="panel">
        <v-expansion-panel-header>
          <h3>FoodCie</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Cooking committee
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-model="panel">
        <v-expansion-panel-header>
          <h3>MediaCie</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Media and website
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-model="panel">
        <v-expansion-panel-header>
          <h3>Lustrum</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Lustrum Committee for the first lustrum of esav (2023-2024)
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-model="panel">
        <v-expansion-panel-header>
          <h3>KCC</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          The Kas Controle Commissie or Cash Auditing Committee is the
          E.S.A.V.'s internal financial advisory body and investigation body.
          The committee is tasked with providing advice on budgets to both the
          treasurer and committees and with ensuring the financial policy as
          approved by the G.M.M. is being adhered to by all parties involved.<br /><br />
          Tho committee answers to the G.M.M. and provides periodic updates on
          the financial health of the association.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
export default {
  name: "BoardPage",
  data() {
    return {
      panel: [],
    };
  },
  computed: {
    // if user is already logged in
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
};
</script>

<style scoped></style>
