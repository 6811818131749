<template>
    <v-dialog v-model="dialog" scrollable max-width="70%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="fetchData" class="ma-2 lustrumgold--text">
           View participants
        </v-btn>
      </template>
      <v-card :loading="loading">
        <v-card-title>Participants</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-list-item
            v-for="p in this.participants"
            :key="p.pk"
            :to="'/members/' + p.participant.pk"
          >
            <v-list-item-content>
              <v-list-item-title
                >{{ p.participant.first_name }}
                {{ p.participant.last_name }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false" class="lustrumgold--text">
            Close
          </v-btn>
          {{ amount_of_participants }} / {{ capacity }} spots taken
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { getAllParticipants } from "@/services/ActivityService";

export default {
  name: "ParticipantsModal",
  data() {
    return {
      dialog: false,
      participants: [],
      loading: false,
    };
  },
  props: ["activity_id", "capacity", "amount_of_participants"],
  methods: {
    async fetchData() {
      // Removing my-component from the DOM
      this.dialog = true;
      this.loading = true;

      await getAllParticipants(this.activity_id).then(
        (response) => {
          this.participants = response;
        },
        (error) => {
          console.log(error);
        }
      );

      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
