<template functional>
  <v-card outlined class="mb-4" md="12" elevation="2">
    <v-card-title>Contact information</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <strong>Location E.S.A.V.</strong><br />
      Engelsbergenstraat 4C<br />
      5616 JC Eindhoven<br />
      Nederland
      <br />
      <br />
      <strong>KvK: </strong>75620715
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <strong>Contact our board</strong><br />
      <v-simple-table>
        <template>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Function</th>
              <th class="text-left">E-mail</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Maud Slütter</td>
              <td>Chairman</td>
              <td>
                <a href="mailto:voorzitter@esav.nl">voorzitter@esav.nl</a>
              </td>
            </tr>
            <tr>
              <td>Fenna Sigmond</td>
              <td>Secretary</td>
              <td>
                <a href="mailto:secretaris@esav.nl">secretaris@esav.nl</a>
              </td>
            </tr>
            <tr>
              <td>Joris Scheublin</td>
              <td>Treasurer</td>
              <td>
                <a href="mailto:penningmeester@esav.nl"
                  >penningmeester@esav.nl</a
                >
              </td>
            </tr>
            <tr>
              <td>Samuel Leenknegt</td>
              <td>Commissioner of internal affairs</td>
              <td>
                <a href="mailto:externalaffairs@esav.nl"
                  >externalaffairs@esav.nl</a
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <strong>Confidants</strong><br />
      <v-simple-table>
        <template>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">E-mail</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Fenna Sigmond</td>
              <td>
                <a href="mailto:vertrouwenspersoon@esav.nl"
                  >vertrouwenspersoon@esav.nl</a
                >
              </td>
            </tr>
            <tr>
              <td>Simon Bergman</td>
              <td>
                <a href="mailto:vertrouwenspersoon@esav.nl"
                  >vertrouwenspersoon@esav.nl</a
                >
              </td>
            </tr>
            <tr>
              <td>Wimco Veerman</td>
              <td>
                <a href="mailto:vertrouwenspersoon@esav.nl"
                  >vertrouwenspersoon@esav.nl</a
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ContactPage",
};
</script>

<style scoped></style>
