<template>
  <v-card class="mx-auto" tile elevation="2" :loading="is_loading">
    <v-card-title>Poll</v-card-title>
    <v-divider />
    <v-alert
      v-if="(!this.poll || Object.keys(poll).length === 0) && !is_loading"
      color="main-color"
      dark
      class="ma-4"
    >
      There is no poll
    </v-alert>
    <v-card-text class="font-weight-bold">{{
      this.poll.question_text
    }}</v-card-text>
    <v-radio-group
      v-if="this.poll.can_vote"
      :v-model="this.vote"
      class="ma-4 mt-0"
    >
      <v-radio
        v-for="(key, value) in this.poll.choices"
        :key="value"
        :label="`${key}`"
        :value="value"
        @change="onVote(value)"
      ></v-radio>
    </v-radio-group>
    <div v-if="!this.poll.can_vote">
      <v-card-text v-for="(key, value) in this.results" :key="value"
        >{{ value }}
        <v-progress-linear
          color="primary"
          height="15"
          :value="percentage(key)"
          dark
        >
          {{ key }} / {{ total_votes }}
        </v-progress-linear>
      </v-card-text>
      <v-card-text
        class="font-italic"
        v-if="!poll.can_vote || Object.keys(poll).length === 0"
        >You already voted</v-card-text
      >
    </div>
  </v-card>
</template>

<script>
import { getPoll, postPollVote } from "@/services/PollService";

export default {
  name: "PollComponent",
  data() {
    return {
      is_loading: false,
      poll: {},
      vote: null,
      results: {},
      total_votes: 0,
    };
  },
  methods: {
    async onVote(choice_id) {
      this.is_loading = true;
      await postPollVote(this.poll.pk, choice_id)
        .then((response) => {
          this.poll = response;
          this.computeResults(this.poll);
          this.is_loading = false;
        })
        .catch(() => (this.is_loading = false));
    },
    computeResults(poll) {
      for (let i = 0; i < Object.keys(poll.choices).length; i++) {
        let choice_id = Object.keys(poll.choices)[i];
        let result = poll.results[choice_id];
        if (result) {
          this.results[poll.choices[Object.keys(poll.choices)[i]]] =
            poll.results[choice_id];
          this.total_votes += poll.results[choice_id];
        } else {
          this.results[poll.choices[Object.keys(poll.choices)[i]]] = 0;
        }
      }
    },
    percentage(val) {
      return (100 * val) / this.total_votes;
    },
  },
  async mounted() {
    this.is_loading = true;
    await getPoll()
      .then((poll) => {
        this.computeResults(poll);
        this.poll = poll;
        this.is_loading = false;
      })
      .catch(() => (this.is_loading = false));
    this.is_loading = false;
  },
};
</script>

<style scoped></style>
