<template>
  <div>
    <v-card outlined class="mb-4" md="12" elevation="2">
      <v-alert
        type="warning"
        dense
        v-if="this.activities.length === 0 && !is_loading"
        >There are no activities
      </v-alert>
      <v-card-title>Activities</v-card-title>
      <v-divider />
      <v-card-text>
        Here you can sign up for activities. Be sure to pay them on time using
        the payment button after signing up.
      </v-card-text>
      <v-card-text>
        <v-expansion-panels class="mb-6" v-model="openPage">
          <ActivityItem
            v-model="openPage"
            v-for="act in this.activities"
            v-bind:key="act.id"
            v-bind:title="act.title"
            v-bind:description="act.description"
            v-bind:activity_id="act.id"
            v-bind:can_register="act.user_can_register"
            v-bind:can_deregister="act.user_can_deregister"
            v-bind:can_pay="act.user_can_pay"
            v-bind:user_is_participant="act.user_is_participant"
            v-bind:location="act.location"
            v-bind:costs="act.costs"
            v-bind:amount_of_participants="act.amount_of_participants"
            v-bind:capacity="act.capacity"
            v-bind:activity_start="act.activity_start"
            v-bind:activity_end="act.activity_end"
            v-bind:isLinked="
              toString(act.id) === toString($route.query.activity)
            "
          ></ActivityItem>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ActivityItem from "../components/ActivityItem.vue";

export default {
  name: "ActivityPage",
  components: {
    ActivityItem,
  },
  data: () => ({ is_loading: true, openPage: null }),

  computed: {
    ...mapGetters({ activities: "activities/getActivities" }),
  },

  methods: {
    ...mapActions("activities", ["fetchActivities"]),
  },

  async mounted() {
    this.is_loading = true;
    await this.fetchActivities().then((respone) => {
      for (let i = 0; i < respone.length; i++) {
        if (respone[i].id === parseInt(this.$route.query.activity)) {
          this.openPage = i;
        }
      }
      return null;
    });
    this.is_loading = false;
  },
};
</script>

<style scoped></style>
