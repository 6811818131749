<template>
  <div class="mb-4">
    <ProfileComponent></ProfileComponent>
    <GaragePage :owner_id="user_id"></GaragePage>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          width="800px"
          v-if="parseInt(user_id) === parseInt(current_user_id)"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="green"
              dark
              x-large
              v-bind="attrs"
              v-on="on"
              class="ma-8"
              width="95%"
          >Add Car
          </v-btn>
        </template>
        <CarForm :isModal="true"></CarForm>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import CarForm from "@/components/CarForm.vue";
import GaragePage from "@/views/GaragePage.vue";
import {mapGetters} from "vuex";
import ProfileComponent from "@/components/ProfileComponent.vue";

export default {
  name: "MyProfilePage",
  components: {ProfileComponent, GaragePage, CarForm},
  data() {
    return {
      user_id: this.$route.params.id,
      dialog: false,
    }
  },
  computed: {
    ...mapGetters({current_user_id: "auth/getCurrentUserId"}),
  },
}
</script>

<style scoped>

</style>