import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueGtag from 'vue-gtag'
import { router } from "./router.js";
import VueX from "vuex";
import store from "./store";
import Notifications from "vue-notification";
import httpClient from "@/common/httpClient.js";
import CKEditor from "@ckeditor/ckeditor5-vue2";

const accessToken = localStorage.getItem("accessToken");
const refreshToken = localStorage.getItem("refreshToken");
if (accessToken) {
  store.dispatch("auth/insertTokens", {
    accessToken: accessToken,
    refreshToken: refreshToken,
  });
}

const userId = localStorage.getItem("userId");
if (userId) {
  store.dispatch("auth/updateUser", { id: userId });
}

Vue.config.productionTip = false;
axios.defaults.headers.common["Content-Type"] = "application/json";
Vue.prototype.$http = httpClient;

Vue.use(VueX);
Vue.use(Notifications);
Vue.use(CKEditor);

Vue.use(VueGtag, {
  config: {
    id: 'G-048QV5TK32',
    params: {
      send_page_view: false
    }
  }
}, router)

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
