import { render, staticRenderFns } from "./AboutPage.vue?vue&type=template&id=75cbcd4a&scoped=true&functional=true"
import script from "./AboutPage.vue?vue&type=script&lang=js"
export * from "./AboutPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "75cbcd4a",
  null
  
)

export default component.exports