import Vue from "vue";

const getAllNews = () =>
  Vue.prototype.$http.get("news/").then((response) => {
    return response.data.results;
  });

// you can pass arguments to use as request parameters/data
const getArticle = (id) =>
  Vue.prototype.$http.get("news/" + id).then((response) => {
    return response.data;
  });

export { getAllNews, getArticle };
