<template>
  <v-card md="12" elevation="2" outlined :loading="is_loading">
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">Beunbaas Profile</div>
        <v-list-item-title class="text-h5 mb-1">
          {{ profile_data.name }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="profile_data.address"
          ><strong>Address:</strong>
          {{ profile_data.address }}</v-list-item-subtitle
        >
        <v-list-item-subtitle v-if="profile_data.phone"
          ><strong>Phone:</strong>
          {{ profile_data.phone }}</v-list-item-subtitle
        >
        <v-list-item-subtitle v-if="profile_data.email"
          ><strong>Email:</strong>
          {{ profile_data.email }}</v-list-item-subtitle
        >
      </v-list-item-content>

      <v-list-item-avatar tile size="100" color="grey">
        <v-img :src="profile_data.picture" v-if="profile_data.picture" />
      </v-list-item-avatar>
    </v-list-item>
    <v-card-text v-html="profile_data.bio"></v-card-text>

    <v-card-actions>
      <v-btn
        outlined
        rounded
        text
        v-if="currentUserId === this.user_id"
        :to="'/members/edit'"
      >
        Edit profile
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getMember } from "@/services/MemberService";
import Vue from "vue";
import { getCurrentUserInfo } from "@/services/AuthService";
import { mapGetters } from "vuex";

export default {
  name: "ProfileComponent",
  data() {
    return {
      is_loading: false,
      user_id: this.$route.params.id,
      profile_data: {},
    };
  },
  computed: {
    ...mapGetters({ currentUserId: "auth/getCurrentUserId" }),
  },
  async mounted() {
    this.is_loading = true;
    if (this.user_id) {
      await getCurrentUserInfo().then((response) => {
        this.current_user_id = response.pk;
      });
      await getMember(this.user_id)
        .then((response) => {
          this.profile_data = response;
          this.is_loading = false;
        })
        .catch(() => (this.is_loading = false));
    } else {
      Vue.notify({
        type: "error",
        title: "No user id provided",
      });
    }
  },
};
</script>

<style scoped></style>
