<template>
  <v-card elevation="2">
    <v-card-title>Add Car To Garage [BETA]</v-card-title>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"> Details</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="success" step="2"> Preview</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form @submit.prevent="checkForm(2)">
            <v-text-field
              v-model="make"
              :error-messages="carMakeError"
              label="Make"
              outlined
              prepend-icon="mdi-car"
            ></v-text-field>

            <v-text-field
              v-model="carmodel"
              :error-messages="carModelError"
              label="Car Model"
              outlined
              prepend-icon="mdi-car"
            ></v-text-field>
            <v-text-field
              v-model="carnickname"
              :error-messages="carNickNameError"
              label="Car Nickname"
              outlined
              prepend-icon="mdi-car"
            ></v-text-field>
            <v-file-input
              v-model="carphoto"
              type="file"
              :error-messages="carPhotoError"
              label="Upload your car picture"
              outlined
              prepend-icon="mdi-camera"
              accept="image/png, image/jpeg"
              @change="onFileChange"
            ></v-file-input>
            <v-card-text>
              Write a small piece of text about your car and its specifications:
            </v-card-text>
            <ckeditor
              :editor="editor"
              v-model="specs"
              :config="editorConfig"
            ></ckeditor>
            <v-divider></v-divider>
          </v-form>

          <v-btn color="primary" @click="checkForm(2)"> Continue</v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-alert dense v-if="success" type="success">
            The form has been sent. You can now see your car in the garage
          </v-alert>
          <v-card elevation="2" class="ma-4" v-if="!success">
            <v-img
              class="white--text align-end"
              height="290px"
              :src="carphotoUrl"
            >
              <v-card-title style="background-color: rgba(0, 0, 0, 0.25)"
                >{{ carnickname }}
              </v-card-title>
            </v-img>

            <v-card-subtitle class="pb-0"> Owned by: You</v-card-subtitle>

            <v-card-text class="text--primary" v-html="specs"></v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
              <v-chip-group
                active-class="deep-purple accent-4 white--text"
                disabled-class="deep-purple accent-4 white--text"
                column
              >
                <v-chip label>{{ make }}</v-chip>
                <v-chip label>{{ carmodel }}</v-chip>
              </v-chip-group>
            </v-card-text>

            <!--        <v-card-actions>-->
            <!--          <v-btn-->
            <!--              color="orange"-->
            <!--              text-->
            <!--          >-->
            <!--            Share-->
            <!--          </v-btn>-->

            <!--          <v-btn-->
            <!--              color="orange"-->
            <!--              text-->
            <!--          >-->
            <!--            Explore-->
            <!--          </v-btn>-->
            <!--        </v-card-actions>-->
          </v-card>

          <v-btn text color="primary" outlined @click="e1 = 1" v-if="!success">
            Back
          </v-btn>
          <v-btn color="primary" @click="sendCar()" v-if="!success">
            Save
          </v-btn>
          <v-btn color="primary" @click="routePush('/garage')" v-if="success && !isModal"
            >Go to garage
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { postCar } from "@/services/GarageService";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  mixins: [validationMixin],
  name: "CarForm",
  props: ["isModal"],
  data() {
    return {
      specs: "",
      e1: 1,
      make: "",
      carnickname: "",
      carmodel: "",
      valid: false,
      carphoto: null,
      carphotoUrl: null,
      is_loading: false,
      success: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "bold",
            "italic",
            "|",
            "undo",
            "redo",
            "-",
            "numberedList",
            "bulletedList",
          ],
        },
      },
    };
  },
  validations: {
    make: { required, maxLength: maxLength(35) },
    carmodel: { required, maxLength: maxLength(35) },
    carnickname: { required, maxLength: maxLength(35) },
    carphoto: { required },
  },
  computed: {
    carMakeError() {
      const errors = [];
      if (!this.$v.make.$dirty) return errors;
      !this.$v.make.maxLength &&
        errors.push("Make must be at most 20 characters long");
      !this.$v.make.required && errors.push("Field is required.");
      return errors;
    },
    carModelError() {
      const errors = [];
      if (!this.$v.carmodel.$dirty) return errors;
      !this.$v.carmodel.maxLength &&
        errors.push("Model must be at most 35 characters long");
      !this.$v.carmodel.required && errors.push("Field is required.");
      return errors;
    },
    carNickNameError() {
      const errors = [];
      if (!this.$v.carnickname.$dirty) return errors;
      !this.$v.carnickname.maxLength &&
        errors.push("Field must be at most 35 characters long");
      !this.$v.carnickname.required && errors.push("Field is required.");
      return errors;
    },
    carPhotoError() {
      const errors = [];
      if (!this.$v.carphoto.$dirty) return errors;
      !this.$v.carphoto.required && errors.push("Field is required.");
      return errors;
    },
  },
  methods: {
    checkForm(nextPage) {
      this.$v.$touch();
      if (this.$v.$invalid === false) {
        this.e1 = nextPage;
      }
    },
    async sendCar() {
      this.is_loading = true;
      let carObj = {
        make: this.make,
        carmodel: this.carmodel,
        specs: this.specs,
        car_photo: this.carphoto,
        nickname: this.carnickname,
      };
      await postCar(carObj)
        .then(() => {
          this.success = true;
          this.is_loading = false;
        })
        .catch(() => (this.is_loading = false));
    },
    onFileChange(e) {
      this.carphotoUrl = URL.createObjectURL(e);
    },
    routePush(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped></style>
