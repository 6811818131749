import axios from "axios";
import Vue from "vue";

const authenticate = (username, password) =>
  axios
    .post(process.env.VUE_APP_API_URL + "token/", {
      username: username,
      password: password,
    })
    .then((response) => {
      return response.data;
    });

const verify = (token) =>
  axios
    .post(process.env.VUE_APP_API_URL + "token/verify/", { token: token })
    .then((response) => {
      return response.data;
    });

const refresh = (refreshToken) =>
  axios
    .post(process.env.VUE_APP_API_URL + "token/refresh/", {
      refresh: refreshToken,
    })
    .then((response) => {
      return response.data;
    });

const resetPassword = (old_password, new_password) =>
  Vue.prototype.$http
    .put(process.env.VUE_APP_API_URL + "change-password/", {
      old_password: old_password,
      new_password: new_password,
    })
    .then((response) => {
      return response.status;
    });

const getCurrentUserInfo = () =>
  Vue.prototype.$http.get("current-user/").then((response) => {
    return response.data;
  });

export { authenticate, refresh, verify, resetPassword, getCurrentUserInfo };
