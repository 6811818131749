import {
  authenticate,
  getCurrentUserInfo,
  refresh,
  verify,
} from "@/services/AuthService";
import Vue from "vue";
import { router } from "@/router";

export const auth = {
  namespaced: true,
  state: {
    accessToken: null,
    refreshToken: null,
    status: { loggedIn: null },
    user: { id: null },
  },

  getters: {
    getCurrentUserId(state) {
      return state.user.id;
    },
  },

  mutations: {
    updateStorage(state, { access, refresh }) {
      state.accessToken = access;
      state.refreshToken = refresh;
      if (access) {
        localStorage.setItem("accessToken", access);
      } else {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userId");
      }
      if (refresh) {
        localStorage.setItem("refreshToken", refresh);
      } else {
        localStorage.removeItem("refreshToken");
      }
    },
    setLoggedIn(state, { success }) {
      state.status.loggedIn = success;
    },
    setUser(state, { id }) {
      state.user.id = id;
      localStorage.setItem("userId", id);
    },
  },
  actions: {
    userLogin({ commit }, usercredentials) {
      return authenticate(
        usercredentials.username,
        usercredentials.password
      ).then((response) => {
        commit("updateStorage", {
          access: response.access,
          refresh: response.refresh,
        });
        commit("setLoggedIn", { success: true });
        getCurrentUserInfo().then((response) => {
          commit("setUser", { id: response.pk });
        });
        return response;
      });
    },
    verifyToken({ commit }, accessToken) {
      verify(accessToken).then(() => {
        commit("updateStorage", {
          access: accessToken,
          refresh: localStorage.getItem("refreshToken"),
        });
        commit("setLoggedIn", { success: true });
      });
    },
    refreshToken({ commit }, refreshToken) {
      return refresh(refreshToken).then((response) => {
        commit("updateStorage", {
          access: response.access,
          refresh: refreshToken,
        });
        commit("setLoggedIn", { success: true });
        getCurrentUserInfo().then((response) => {
          commit("setUser", { id: response.pk });
        });
        return response.access;
      });
    },
    insertTokens({ commit }, tokens) {
      commit("updateStorage", {
        access: tokens.accessToken,
        refresh: tokens.refreshToken,
      });
      commit("setLoggedIn", { success: true });
    },
    updateUser({ commit }, user) {
      commit("setUser", { id: user.id });
    },
    logOut({ commit, state }, context = {}) {
      if (state.status.loggedIn === true) {
        commit("setLoggedIn", { success: false });
        commit("updateStorage", { access: null, refresh: null });
        localStorage.removeItem("userId");
        commit("setUser", { id: null });
        if (context.routerPush) {
          router.push(context.routerPush);
        }
        Vue.notify({
          type: "warning",
          title: "Logged Out",
          text: "You have been logged out.",
        });
      }
    },
  },
};
