import Vue from "vue";
import Vuex from "vuex";
import { auth } from "./modules/auth"; // how logging in and out is handled
import { notifications } from "./modules/notifications"; // how notifications can be created and shown
import { router } from "./modules/router"; // our router settings
import { activities } from "./modules/activities";
import { calendar } from "@/store/modules/calendar";
//import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex);

//const debug = process.env.NODE_ENV !== 'production'

// setting up our vuex system.
export default new Vuex.Store({
  modules: {
    activities,
    auth,
    notifications,
    router,
    calendar,
  },
});
