import Vue from "vue";

const getMembersList = (page_id = 1) =>
  Vue.prototype.$http.get("member/?page=" + page_id).then((response) => {
    return response.data;
  });

// you can pass arguments to use as request parameters/data
const getMember = (id) =>
  Vue.prototype.$http.get("member/" + id + "/").then((response) => {
    return response.data;
  });

const updateProfile = (id, data) =>
  Vue.prototype.$http.put("member/" + id + "/", data).then((response) => {
    return response.data;
  });

export { getMembersList, getMember, updateProfile };
