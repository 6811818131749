// the router object
// has to ability to display that it is busy loading.
export const router = {
  namespaced: true,
  state: { isLoading: false },
  mutations: {
    setLoading(state, loading) {
      state.isLoading = loading;
    },
  },
};
