// VueX store for notification messages
import { v4 as uuidv4 } from "uuid";

// initialise state without any messages (empty array)
const initialState = { notifications: [] };

export const notifications = {
  namespaced: true,
  state: initialState,
  actions: {
    // add a notification to display
    addNotification({ commit }, notification) {
      // mutate the store by adding
      commit("newMessage", notification);
    },
    removeNotification({ commit }, notification) {
      // mutate the store by deletion
      commit("deleteMessage", notification);
    },
  },
  mutations: {
    newMessage(state, notification) {
      // add new message to the arrays with identifier
      state.notifications.push({
        ...notification,
        id: uuidv4(), // add unique identifier
      });
    },
    deleteMessage(state, notificationToRemove) {
      // keep all notifications that are not assigned to this id
      state.notifications = state.notifications.filter(
        (notification) => notification.id != notificationToRemove.id
      );
    },
  },
};
