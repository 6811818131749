<template>
  <div>
    <PollComponent v-if="loggedIn" class="mb-4" />
    <calendar class="mb-4" />
    <link-box-component class="mb-4" />
  </div>
</template>

<script>
import Calendar from "./Calendar";
import LinkBoxComponent from "@/components/LinkBoxComponent.vue";
import PollComponent from "@/components/PollComponent.vue";

export default {
  name: "SidebarComponent",
  components: { PollComponent, Calendar, LinkBoxComponent },
  computed: {
    // if user is already logged in
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
};
</script>

<style scoped></style>
