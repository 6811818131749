import Vue from "vue";

const getPoll = () =>
  Vue.prototype.$http.get("poll/").then((response) => {
    return response.data;
  });

// you can pass arguments to use as request parameters/data
const postPollVote = (id, choice_id) =>
  Vue.prototype.$http
    .post("poll/", { choice_id: choice_id })
    .then((response) => {
      return response.data;
    });

export { getPoll, postPollVote };
