<template functional>
  <v-card outlined class="mb-4" md="12" elevation="2">
    <v-card-title>404 Not Found</v-card-title>
    <v-divider></v-divider>
    <v-card-text> </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "404",
};
</script>

<style scoped></style>
