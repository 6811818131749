<template>
  <div>
    <v-row no-gutters>
      <v-col v-for="car in cars" :key="car.id" cols="12" sm="12" md="6">
        <CarCard :car="car"></CarCard>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-progress-circular
        indeterminate
        color="secondary"
        v-if="isLoading && !next_available"
      ></v-progress-circular>
      <v-btn
        color="primary"
        elevation="2"
        x-large
        :disabled="!next_available"
        :loading="isLoading"
        @click="loadCarPage"
        class="mt-8"
        v-if="!(!next_available && owner_id)"
        >Load More Cars
      </v-btn>
    </div>
  </div>
</template>

<script>
import { getCars } from "@/services/GarageService";
import CarCard from "@/components/CarCard.vue";

export default {
  name: "GaragePage",
  components: {CarCard},
  props: ["owner_id"],
  data() {
    return {
      isLoading: false,
      cars: [],
      current_page: 1,
      next_available: false,
      make_filter: null,
    };
  },
  async beforeMount() {
    let params = null
    if (this.owner_id) {
      params = {owner_id: this.owner_id}
    }
    this.isLoading = true;
    await getCars(this.current_page, params).then((response) => {
      this.cars = response.results;
      this.isLoading = false;
      this.next_available = Boolean(response.next);
    });
    this.isLoading = false;
  },
  methods: {
    async loadCarPage() {
      if (this.next_available === true) {
        let params = null
        if (this.owner_id) {
          params = {owner_id: this.owner_id}
        }
        this.isLoading = true;
        await getCars(this.current_page + 1, params).then((response) => {
          this.cars = this.cars.concat(response.results);
          this.next_available = Boolean(response.next);
          this.current_page += 1;
          this.isLoading = false;
        });
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
