import { render, staticRenderFns } from "./CommitteePage.vue?vue&type=template&id=1bec0a1f&scoped=true&functional=true"
import script from "./CommitteePage.vue?vue&type=script&lang=js"
export * from "./CommitteePage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "1bec0a1f",
  null
  
)

export default component.exports