import Vue from "vue";
import Router from "vue-router";

import HomePage from "./views/HomePage.vue";
import ActivityPage from "./views/ActivityPage.vue";
import AboutPage from "./views/AboutPage.vue";
import LoginForm from "@/components/LoginForm.vue";
import ContactPage from "@/views/ContactPage.vue";
import JoinUsPage from "@/views/JoinUsPage.vue";
import ChangePasswordForm from "@/components/ChangePasswordForm.vue";
import MembersListComponent from "@/components/MembersListComponent.vue";
import EditProfileForm from "@/components/EditProfileForm.vue";
import BoardPage from "@/views/BoardPage.vue";
import CommitteePage from "@/views/CommitteePage.vue";
import store from "@/store";
import NotFound from "@/views/NotFound.vue";
import FilesPage from "@/views/FilesPage.vue";
import GaragePage from "@/views/GaragePage.vue";
import MyProfilePage from "@/views/MyProfilePage.vue";
import LustrumPage from "@/views/LustrumPage.vue"

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    { path: "/", name: "home", component: HomePage },
    { path: "/login", name: "login", component: LoginForm },
    { path: "/activities", name: "activities", component: ActivityPage },
    { path: "/join-us", name: "join-us", component: JoinUsPage },
    { path: "/about-us", name: "about-us", component: AboutPage },
    { path: "/contact", name: "contact-us", component: ContactPage },
    {
      path: "/change-password",
      name: "change-password",
      component: ChangePasswordForm,
    },
    { path: "/members", name: "members-list", component: MembersListComponent },
    { path: "/members/edit", name: "members-edit", component: EditProfileForm },
    {
      path: "/members/:id",
      name: "members-retrieve",
      component: MyProfilePage,
    },
    { path: "/boards", name: "boards", component: BoardPage },
    { path: "/committees", name: "committees", component: CommitteePage },
    { path: "/downloads", name: "files", component: FilesPage },
    { path: "/garage", name: "garage", component: GaragePage },
    { path: "/lustrum", name: "lustrum", component: LustrumPage},
    { path: "/404", component: NotFound },
    { path: "*", redirect: "/404" },
  ],
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    "/login",
    "/",
    "/contact",
    "/about-us",
    "/join-us",
    "/boards",
    "/committees",
    "/lustrum"
  ];
  const authRequired = !publicPages.includes(to.path);
  //
  // // if (authRequired && !loggedIn) {
  // //   return next('/login');
  // // }
  if (!store.state.auth.status.loggedIn && authRequired) {
    // If not authenticated, add a path where to redirect after login.
    if (!to.query.redirect) {
      router.push({ name: "login", query: { redirect: to.fullPath } });
    }
  }

  next();
});
