import { render, staticRenderFns } from "./LustrumPage.vue?vue&type=template&id=68045095&scoped=true&functional=true"
import script from "./LustrumPage.vue?vue&type=script&lang=js"
export * from "./LustrumPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "68045095",
  null
  
)

export default component.exports