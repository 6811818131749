import Vue from "vue";

const getCars = (page = 1, params = null) =>
  Vue.prototype.$http.get("garage/?page=" + page, {params: params}).then((response) => {
    return response.data;
  });

const getCar = (id) =>
  Vue.prototype.$http.get("garage/" + id + "/").then((response) => {
    return response.data;
  });

const postCar = (car_data) =>
  Vue.prototype.$http
    .post("garage/", car_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    });

const putCar = (id, car_data) =>
  Vue.prototype.$http.post("garage/" + id + "/", car_data).then((response) => {
    return response.data;
  });

const deleteCar = (id) =>
  Vue.prototype.$http.delete("garage/" + id + "/").then((response) => {
    return response.data;
  });

export { getCars, getCar, postCar, putCar, deleteCar };
