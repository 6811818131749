<template>
  <v-card outlined :loading="loading">
    <v-card-title>Change your password</v-card-title>
    <v-divider></v-divider>
    <v-alert v-if="incorrectAuth" type="error" dense class="ma-4">
      Old password is incorrect.
    </v-alert>
    <v-alert v-if="success" type="success" dense class="ma-4">
      Your password has been changed.
    </v-alert>
    <v-form ref="form" @submit.prevent="rPassword" v-if="!success">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              v-model="old_password"
              label="Current password"
              type="password"
              required
            />
            <div v-show="tried && !old_password" class="invalid-feedback">
              Old password is required
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              type="password"
              v-model="new_password"
              label="New password"
              :rules="passwordRules"
            />
            <div v-show="tried && !new_password" class="invalid-feedback">
              New password is required
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              type="password"
              v-model="confirm_new_password"
              :rules="passwordRules"
              label="New password confirmation"
            />
            <div
              v-show="tried && !confirm_new_password"
              class="invalid-feedback"
            >
              New password is required
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          color="primary"
          :disabled="
            loading || !old_password || !new_password || !confirm_new_password
          "
        >
          Log-in
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { resetPassword } from "@/services/AuthService";

export default {
  name: "ChangePasswordForm",
  mixins: [validationMixin],

  validations: {},

  data: () => ({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
    loading: false,
    incorrectAuth: false,
    tried: false,
    success: false,
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        ).test(v) ||
        "The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc), min. 8 chars",
    ],
  }),
  computed: {
    // if user is already logged in
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  methods: {
    async rPassword() {
      this.loading = this.tried = true;
      if (this.new_password !== this.confirm_new_password) {
        this.$notify({
          type: "error",
          title: "Password Confirmation",
          message: "Password confirmation is not equal to new password.",
        });
        this.loading = false;
        return;
      }
      resetPassword(this.old_password, this.new_password).then((success) => {
        if (success === 200) {
          this.$notify({
            type: "success",
            title: "Password Changed",
            message: "Password has been changed",
          });
          this.success = true;
        } else if (success === 400) {
          this.incorrectAuth = true;
        }
      });
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
