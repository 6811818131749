<template>
  <v-card outlined class="mb-4" md="12" elevation="2">
    <v-card-title>Update your profile</v-card-title>
    <v-divider />
    <v-alert v-if="error" type="error" dense>
      Something went wrong, your profile hasn't been updated.
    </v-alert>
    <v-alert dense v-if="success" type="success">
      Your profile has been updated.
    </v-alert>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation v-if="!success">
        <v-text-field
          v-model="firstName"
          :counter="10"
          :rules="nameRules"
          label="First name"
          required
        ></v-text-field>
        <v-text-field
          v-model="lastName"
          :counter="10"
          :rules="nameRules"
          label="Last name"
          required
        ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
        ></v-text-field>

        <v-text-field v-model="street" label="Street"></v-text-field>

        <v-text-field
          v-model="house_number"
          label="House number"
        ></v-text-field>
        <v-text-field v-model="zipcode" label="Zip code"></v-text-field>
        <v-text-field v-model="city" label="City"></v-text-field>
        <v-text-field v-model="study" label="Study / Occupation"></v-text-field>

        <v-checkbox
          v-model="public_address"
          label="I want to display my address to fellow members."
          required
        ></v-checkbox>

        <v-checkbox
          v-model="public_contact"
          label="I want to display my email and phone number to fellow members."
          required
        ></v-checkbox>

        <v-checkbox
          v-model="public_study"
          label="I want to display my study/occupation to fellow members."
          required
        ></v-checkbox>

        <v-btn color="orange" @click="sendForm"> Update profile </v-btn>
      </v-form>

      <v-btn
        color="orange"
        :to="'/members/' + this.current_user_id"
        v-if="success"
      >
        Show my profile
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { updateProfile } from "@/services/MemberService";
import { getCurrentUserInfo } from "@/services/AuthService";

export default {
  name: "EditProfileForm",
  data: () => ({
    current_user_id: null,
    error: false,
    success: false,
    valid: true,
    firstName: "",
    lastName: "",
    nameRules: [(v) => !!v || "Name is required"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    bio: "",
    street: "",
    house_number: "",
    zipcode: "",
    city: "",
    phone_number: "",
    study: "",
    public_contact: false,
    public_address: false,
    public_study: false,
  }),
  methods: {
    async sendForm() {
      const is_valid = this.$refs.form.validate();
      if (is_valid === true) {
        this.is_loading = true;

        await updateProfile(this.current_user_id, {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          street: this.street,
          number: this.house_number,
          zipcode: this.zipcode,
          phone: this.phone_number,
          study: this.study,
          city: this.city,
          public_address: this.public_address,
          public_study: this.public_study,
          public_contact: this.public_contact,
        })
          .then(() => {
            this.is_loading = false;
            this.success = true;
          })
          .catch(() => {
            this.is_loading = false;
            this.error = true;
          });
      }
    },
  },
  async mounted() {
    await getCurrentUserInfo().then((response) => {
      this.current_user_id = response.pk;
      this.firstName = response.first_name;
      this.lastName = response.last_name;
      this.email = response.email;
      this.street = response.street;
      this.house_number = response.number;
      this.city = response.city;
      this.zipcode = response.zipcode;
      this.phone_number = response.phone;
      this.study = response.study;
      this.public_contact = response.public_contact;
      this.public_address = response.public_address;
      this.public_study = response.public_study;
    });
  },
};
</script>

<style scoped></style>
