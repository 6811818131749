import { getCalendarData } from "@/services/CalendarService";

export const calendar = {
  namespaced: true,
  state: { calendar: [] },
  getters: {
    getCalendar(state) {
      return state.calendar;
    },
  },
  actions: {
    async fetchCalendar({ commit }) {
      const response = await getCalendarData();
      commit("SET_CALENDAR", response);
    },
  },
  mutations: {
    SET_CALENDAR(state, data) {
      state.calendar = data;
    },
  },
};
